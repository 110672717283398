import React, { useState, useEffect, useRef } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {
    TextField,
    Card,
    Paper,
    Box,
    Container,
    CircularProgress,
    useTheme,
    ListItemIcon,
    ListItemText,
    Hidden,
    Avatar,
    Button,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { SentMessage, ReceivedMessage } from './bubbles';
import { Message, RephraseMessage, Source, chatSelector, rephraseChat } from '../../lib/ducks/chatDuck';
import { WelcomeMessage } from '../welcomeMessage';
import { useAppSelector, useAppDispatch } from '../../lib/hook';
import { authSelector, SourceTable } from '../../lib/ducks/authDuck';
import { ChatApi } from '../../lib/apiActions';
import { AlertDialog, InputDialog } from '../dialogs/dialogs';
import { text } from 'body-parser';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SourcesTables } from '../../lib/ducks/chatDuck'
import CheckIcon from '@mui/icons-material/Check';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getPipeColor } from '../../utils/pipeColor';
import { ProSidebarProvider } from 'react-pro-sidebar';
import OpenerButton from '../CustomerContact/OpenerButton';
import CustomerContactFormDialog from '../CustomerContact/CustomerContactFormDialog';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

const PopupChat = (props: any) => {
    const { messages, handleAbort } = props
    const [textField, setTextField] = useState("");
    const user = useAppSelector(authSelector).user;
    const selectedChatId = useAppSelector(chatSelector).selectedChatId;
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const theme = useTheme()
    const bottomRef = useRef<HTMLDivElement>(null);
    const lastRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedSources, setSelectedSources] = useState<SourceTable[]>([]);
    const [filteredSourceTables, setFilteredSourceTables] = useState<SourceTable[]>([]);
    const tableNames = user?.roles?.find(role => role.name === selectedPipe)?.source_tables || []; // table names based on selected pipe
    const [isFormOpen, setFormOpen] = useState(false);
    // const [isHover, setHover] = useState(false);

    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [showArrow, setShowArrow] = useState(false);

    const onScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setShowArrow(false);
            //console.log("bottom")
        } else {
            setShowArrow(true);
        }
    };

    const handleSelectedSources = (source: SourceTable | null) => {
        if (source === null) {
            setSelectedSources([]);
            return;
        }
        if (selectedSources.includes(source)) {
            setSelectedSources(prev => {
                const updatedSources = prev.filter(item => item !== source);
                return updatedSources;
            });
        } else {
            setSelectedSources(prev => {
                const updatedSources = [...prev, source];
                return updatedSources;
            });
        }
    };

    const scrollToBottom = () => {
        try {
            bottomRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        } catch (ex) {

        }
    };

    const handleRephrase = async (message: string, save_message: boolean) => {
        console.log("handleRephrase", message)
        if (props.isLoading || message.trim() === "") {
            setTextField("");
            return;
        }
        const selectedSourcesArray = selectedSources.map((source: SourceTable) => source.names).flat(1);
        //console.log("selectedSourcesArray", selectedSourcesArray)
        props.rephraseMessage(message, selectedSourcesArray);

        setTextField("");
    }


    const handleSend = async (text: string, deniedSources: string[]) => {
        console.log("handleSend", text)
        if (props.isLoading) {
            return;
        }

        const selectedSourcesArray = selectedSources.map((source: SourceTable) => source.names).flat(1);
        //console.log("selectedSourcesArray", selectedSourcesArray)
        props.sendMessage(text, deniedSources, selectedSourcesArray)
    }

    const customerContactButtonContent = () => {
        return (
            <>
                <ContactPhoneIcon style={{ marginRight: "8px"}}/>
                <Typography
                        sx={{
                            // fontWeight: "600",
                            // fontSize: "16px",
                            // opacity: isHover ? 1 : 0,
                            // transition: 'opacity 0.4s ease-in-out',
                            // whiteSpace: 'nowrap',
                            // transform: isHover ? "translateX(0)" : "translateX(50px)",
                        }}
                    >
                    {/* {isHover ? "Pyydä juristin yhteydenottoa" : ""} */}
                    Pyydä juristin yhteydenottoa
                </Typography>
            </>
            );
        };

    useEffect(() => {
        const newFilteredSourceTables = SourcesTables.filter(source =>
            Array.isArray(tableNames) && tableNames.includes(source.names[0])
        );
        setFilteredSourceTables(newFilteredSourceTables);
        setSelectedSources([]);
    }, [selectedPipe]);

    useEffect(() => {
        if (props.messages) {
            scrollToBottom();
        }
    }, [props.messages, lastRef.current?.lastElementChild]);

    useEffect(() => {
        console.log("useeffect selectedChatId", selectedChatId)
    }, [selectedChatId])
    //console.log("messages", props.messages)

    return (
        <>

            {selectedChatId || messages?.length > 0 ?
                <Box
                    onScroll={onScroll}
                    sx={{
                        overflow: 'auto', overflowY: 'auto', display: 'flex', justifyContent: 'center', flex: 1
                    }} >
                    <Box ref={lastRef}
                        sx={{
                            maxWidth: '880px', paddingTop: 5, width: '100%', height: '100%'
                        }} >
                        {messages?.length === 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    minHeight: "80vh"
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }

                        {messages?.map((message: Message, index: any) => {
                            if (message.role === 1) {
                                return (
                                    <SentMessage
                                        key={"message" + index}
                                        text={message.content}
                                    />
                                )
                            }
                            if (message.role === 2) {
                                return (
                                    <ReceivedMessage
                                        key={"message" + index}
                                        message={message}
                                        sendMessage={handleSend}
                                        handleAbort={handleAbort}
                                        rephraseMessage={handleRephrase}
                                        lastMessage={messages.length == index + 1}
                                    />
                                )
                            }
                        })}
                        <Box ref={bottomRef} sx={{ minHeight: '105px', width: '100%', flexGrow: 1 }} onScroll={onScroll} />
                    </Box>
                </Box >

                :
                <WelcomeMessage userName={user?.email} mockChat={props.mockChat} />
            }


            <Box
                sx={{
                    marginLeft: '-8px',
                    marginBottom: '32px',
                    position: 'fixed',
                    bottom: 0,
                    //minHeight: '100px',
                    maxWidth: '880px',
                    width: { xs: 'calc(95% - 80px)', sm: 'calc(95% - 450px)' },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    //backgroundColor: "grey.800",
                    backgroundColor: 'transparent'
                }}
            >
                <Card
                    sx={{
                        //margin: 1,
                        //p: 3,
                        padding: 0,
                        //padding: "8px 12px 8px 12px",
                        borderRadius: "8px",
                        width: '100%',
                        maxWidth: '880px',
                        minHeight: '40px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        //backgroundColor: "grey.800",
                        //backgroundColor: '#0e0e0e99',
                        backgroundColor: theme.palette.boxShade,
                        backdropFilter: 'blur(40px)',
                    }}
                >
                    <TextField
                        helperText={selectedSources.length > 1 ? "Useat lähdevalinnat saattavat hidastaa vastausten saantia" : ""}
                        sx={{
                            width: '80%'
                        }}
                        id="standard-basic"
                        variant="standard"
                        multiline
                        placeholder="Kirjoita viesti"
                        onChange={(event) => setTextField(event.target.value)}
                        value={textField}
                        disabled={props.isLoading}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter' && !ev.shiftKey) {
                                // Do code here
                                ev.preventDefault();
                                handleRephrase(textField, true);
                            }
                        }}
                        InputProps={{
                            disableUnderline: true, // <== added this
                        }}
                    />
                    <Box>
                        <Button
                            sx={{ p: 0, width: '24px' }}
                            onClick={() => handleRephrase(textField, true)}
                            //color="primary"
                            //color={getPipeColor(selectedPipe)}
                            //loading={props.isLoading}
                            disabled={props.isLoading}
                            endIcon={
                                <Avatar
                                    sx={{
                                        width: '24px', height: '24px',
                                        //bgcolor: (theme) => theme.palette.primary.main 
                                        bgcolor: getPipeColor(selectedPipe)
                                    }}
                                    color='primary'
                                >
                                    <SendIcon
                                        sx={{ width: '14px', height: '14px' }}
                                        fontSize='small'
                                    />
                                </Avatar>
                            }
                        />
                        {user?.type == "lawyer" &&
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={menuOpen ? 'long-menu' : undefined}
                                aria-expanded={menuOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        }
                        {user?.type == "lawyer" &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleSelectedSources(null)}>
                                {selectedSources.length === 0 && (
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>Oletuslähde</ListItemText>
                            </MenuItem>
                            {filteredSourceTables.map((source) => (
                                <MenuItem key={source.displayName} onClick={() => handleSelectedSources(source)}>
                                    {selectedSources.includes(source) && (
                                        <ListItemIcon>
                                            <CheckIcon fontSize="small" />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText>{source.displayName}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                        }
                    </Box>
                </Card>
                {showArrow &&
                    <IconButton sx={{ marginLeft: "calc(100% + 60px)", position: 'absolute' }} onClick={scrollToBottom}>
                        <ArrowDownwardIcon />
                    </IconButton>
                }
            </Box>
            {user?.type == "entrepreneur" &&
            <Box
                sx={{
                    //marginLeft: '-8px',
                    marginBottom: '28px',
                    position: 'fixed',
                    right: '30px',
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    // backgroundColor: "grey.800",
                    backgroundColor: 'transparent',
                }}>
                <>
                    <Box 
                    // onMouseEnter={() => setHover(true)} 
                    // onMouseLeave={() => setHover(false)}
                    style={{
                        // display: 'flex',
                        // transition: 'width 0.4s ease-in-out',
                        // width: isHover ? '267px' : "30px", 
                        // alignItems: "center",
                        // justifyContent: "flex-end", // flex-end or right
                      }}
                    >
                        <OpenerButton
                        onClick={() => setFormOpen(true)}
                        content={customerContactButtonContent}
                        sxStyle={{borderRadius: "100px"}}
                        style={{ variant: "contained", color: "primary"}}
                        />
                        <CustomerContactFormDialog
                        isOpen={isFormOpen}
                        handleClickClose={() => setFormOpen(false)}
                        user={user}
                        />
                    </Box>
                </>
            </Box>
        }
        </>
    );
}



export default PopupChat;